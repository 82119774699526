<template>
  <section v-if="!loged" class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <!-- <div class="columns is-centered">
          <div class="column is-one-third"></div>
          <div class="column is-one-third">
            <figure class="image is-128x128 is-2by2">
              <img src=".\assets\acsa_logo.jpeg" />
            </figure>
          </div>
          <div class="column is-one-third"></div>
        </div> -->

        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <form @submit.prevent="doLogin" class="box">
              <img src=".\assets\acsa_base_gran.png" />

              <div class="field">
                <label for="" class="label">Correu</label>
                <div class="control has-icons-left">
                  <input
                    type="email"
                    placeholder="nom@example.com"
                    class="input"
                    v-model="email"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Contrasenya</label>
                <div class="control has-icons-left">
                  <input
                    type="password"
                    placeholder="*******"
                    class="input"
                    v-model="password"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
              <br />
              <div class="field">
                <button
                  class="button is-link is-fullwidth"
                  :class="{ 'is-loading': isLoading }"
                >
                  Iniciar sessió
                </button>
              </div>
            </form>
            <br />
            <h6 class="subtitle is-6 is-pulled-right">
              Powered by

              <a href="www.senianet.com">Sènia Informàtica</a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- una vez validado -->
  <section class="hero is-fullheight" v-else>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column">
            <div class="box" justify='end'>
              <div class="field">
                <!-- <span class="icon is-medium is-left">
                  <i class="fa fa-qrcode"></i>
                </span>-->
                <b class="title is-5"></b> 
                 
                <button @click="logOut" class="button is-link is-small">
                  Desconectar Usuari &nbsp; 
                  <i class="fa fa-user"></i>
                </button>
              </div>
              
              <img src=".\assets\acsa_base_gran.png" />
              
              
          <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            
              <div class="field">
                <label for="" class="label">Telèfon del participant : </label>
                <div class="control has-icons-left">
                  <input                  
                    type="number"
                    placeholder="Introdueix el telèfon"
                    class="input"
                    v-model="elTel"
                    @input="checkNumber(elTel)"
                    :disabled=bloqNumber
                    
                    required
                  />
                </div>
              </div>

              
              <div class="field" v-if="addClient">
                <label for="" class="label">Nom i Cognom del participant :</label>
                <div class="control has-icons-left">
                  <input
                    type="text"
                    placeholder="Introdueix el nom i cognom"
                    class="input"
                    v-model="this.clientName"
                    maxlength = "50"
                    required
                  />
                </div>
              </div>

              <div class="field" v-if="existClient">
                <label for="" class="label">Nom i Cognom del participant :</label>
                <div class="control has-icons-left">
                  <input
                    type="text"
                    placeholder="Introdueix el nom i cognom"
                    class="input"
                    v-model="this.clientName"
                    maxlength = "50"
                    required
                  />
                </div>
              </div>
              <br>

              

              <br />
              <div class="field" v-if="addClient && !noReady">
                <button
                  class="button is-link is-fullwidth"
                  :class="{ 'is-loading': isLoading }"
                  @click="onValidNumber(elTel)"
                >
                  Participar
                </button>
              </div>

              <div class="field" v-if="existClient && !noReady">
                <button
                  class="button is-link is-fullwidth"
                  :class="{ 'is-loading': isLoading }"
                  @click="onValidNumber(elTel)"
                >
                  Participar
                </button>
              </div>

              <div class="field" v-if="noReady">
                <button
                  class="button is-link is-fullwidth"
                  :class="{ 'is-loading': isLoading }"
                  :disabled=true
                >
                  Participar
                </button>
              </div>
          </div>
        </div>
              
              
              
              

              <!--<div class="field">
                <qrcode-stream
                  :camera="camera"
                  @decode="onDecode"
                  @init="onInit"
                >
                  <div v-if="validationPending" class="validation-pending">
                    Validant...
                  </div>
                </qrcode-stream>
              </div>-->
              <!-- part validada -->
              <div v-if="waitValidation" class="field">
                Campanyes on participa:
                <div
                  v-for="room in roomsDisponibles"
                  :key="room.id"
                  class="field"
                >
                  <button
                    v-if="roomIsSelected(room)"
                    @click="changeRoomState(room)"
                    class="button is-link is-fullwidth m-1"
                    :value="room"
                  >
                    <b>{{ room }}</b>
                  </button>
                  <button
                    v-else
                    @click="changeRoomState(room)"
                    class="button is-light is-fullwidth m-1"
                    :value="room"
                  >
                    {{ room }}
                  </button>
                </div>

                Numero de participacions:
                <!-- opcio 1 -->
                <div class="field is-fullwidth" v-if="numberSelected === 10">
                  <button
                    @click="numberSelected = 3"
                    class="button is-light is-one-quarter m-1"
                    value="n1"
                  >
                    3
                  </button>
                  <button
                    @click="numberSelected = 5"
                    class="button is-light is-one-quarter m-1"
                    value="n5"
                  >
                    5
                  </button>
                  <button
                    @click="numberSelected = 7"
                    class="button is-light is-one-quarter m-1"
                    value="n5"
                  >
                    7
                  </button>
                  <button class="button is-link is-one-quarter m-1" value="10">
                    <b>10</b>
                  </button>
                </div>
                <!-- opcio 2 -->
                <div
                  class="field is-fullwidth"
                  v-else-if="numberSelected === 7"
                >
                  <button
                    @click="numberSelected = 3"
                    class="button is-light is-one-quarter m-1"
                    value="n1"
                  >
                    3
                  </button>
                  <button
                    @click="numberSelected = 5"
                    class="button is-light is-one-quarter m-1"
                    value="n1"
                  >
                    5
                  </button>

                  <button class="button is-link is-one-quarter m-1" value="7">
                    <b>7</b>
                  </button>
                  <button
                    @click="numberSelected = 10"
                    class="button is-light is-one-quarter m-1"
                    value="n10"
                  >
                    10
                  </button>
                </div>
                <!-- opcio 3 -->
                <div
                  class="field is-fullwidth"
                  v-else-if="numberSelected === 5"
                >
                  <button
                    @click="numberSelected = 3"
                    class="button is-light is-one-quarter m-1"
                    value="n5"
                  >
                    3
                  </button>
                  <button class="button is-link is-one-quarter m-1" value="5">
                    <b>5</b>
                  </button>
                  <button
                    @click="numberSelected = 7"
                    class="button is-light is-one-quarter m-1"
                    value="n10"
                  >
                    7
                  </button>
                  <button
                    @click="numberSelected = 10"
                    class="button is-light is-one-quarter m-1"
                    value="n10"
                  >
                    10
                  </button>
                </div>
                <!-- opcio 4 -->
                <div class="field is-fullwidth" v-else>
                  <button class="button is-link is-one-quarter m-1" value="3">
                    <b>3</b>
                  </button>
                  <button
                    @click="numberSelected = 5"
                    class="button is-light is-one-quarter m-1"
                    value="n5"
                  >
                    5
                  </button>
                  <button
                    @click="numberSelected = 7"
                    class="button is-light is-one-quarter m-1"
                    value="n5"
                  >
                    7
                  </button>
                  <button
                    @click="numberSelected = 10"
                    class="button is-light is-one-quarter m-1"
                    value="n10"
                  >
                    10
                  </button>
                </div>
                <!-- final OPCIONS -->
              </div>
              <div v-if="waitValidation" class="field">
                <span class="icon is-medium is-left">
                  <i class="fa fa-address-card"></i>
                </span>
                <b class="title is-5">{{ result.nomCognom }}</b>
              </div>
              <div
                v-if="waitValidation"
                class="field is-grouped has-addons has-addons-right"
              >
                <div class="control">
                  <button
                    
                    class="button is-link is-large"
                    :class="{ 'is-loading': isLoading }"
                  >
                    <span class="icon is-small is-left">
                      <i class="fa fa-check"></i>
                    </span>
                  </button>
                </div>
                <div class="control">
                  <button @click="resetQR" class="button is-danger is-large">
                    <span class="icon is-small is-left">
                      <i class="fa fa-times"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { db } from "./firebase";

import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  arrayUnion,
  addDoc, //AQUIII
} from "firebase/firestore";
//import { QrcodeStream } from "vue3-qrcode-reader";

export default {
  name: "App",

  components: {
    //QrcodeStream,
  },
  mounted() {
    
    if (localStorage.nom) {
      this.loged = true;
      this.myName = localStorage.nom;
    }
  },

  data() {
    return {
      isLogin: true,
      isLoading: false,
      email: "",
      password: "",
      myName: "",
      loged: false,
      isValid: undefined,
      camera: "auto",
      result: [{}],
      ticketNumber: null,
      roomsDisponibles: [],
      roomsSelecionadas: [],
      waitValidation: false,
      numberSelected: 1,
      rooms: "",
      clients: "",
      clientName: "",
      addClient: false,
      existClient: false,
      noReady: true,
      readyName:false,
      readyNewName: false,
      enProceso: false,
      clientResult: "",
      clientRef: "",
      elTel: "",
      bloqNumber: false,
      justOneCreate: true,
      elPhone: "",
    };
  },
  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },
  },

  methods: {
    logOut() {
      //localStorage.removeItem(localStorage.nom);
      localStorage.nom = "";
      this.loged = false;
    },
    async doLogin() {
      this.isLoading = true;
      try {
        const q = query(collection(db, "shops"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.data().usuari === this.email) {
            if (doc.data().contrasenya === this.password) {
              this.loged = true;
              this.myName = doc.data().name;
            }
          }
        });
        if (this.loged) {
          this.$toast.success("Inici correcte");
          localStorage.setItem("nom", this.myName);
        } else {
          this.$toast.warning("Email o Contrasenya incorrectes");
        }
        this.email = this.password = "";
      } catch (error) {
        console.error(error.message);
        this.$toast.error(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    onInit(promise) {
      promise.catch(function () {
        this.userMediaSupported = false;
      });
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      (this.roomsDisponibles = []), (this.result = "");
      this.numberSelected = 1;
      this.rooms = "";
      this.turnCameraOff();
      if (content.startsWith("{")) {
        this.result = JSON.parse(content);
        //comprobar si es un campo valido
        //ve a la base de datos y dime a que campañas pertenezco y muestralas junto los numeros de tickets
        const q = query(collection(db, "rooms"));
        const querySnapshot = await getDocs(q);
        this.rooms = querySnapshot;
        querySnapshot.forEach((theRoom) => {
          if (
            theRoom.data().checkedShops.includes(this.myName) &&
            !theRoom.data().finished
          ) {
            this.roomsDisponibles.push(theRoom.data().name);
            this.roomsSelecionadas.push(theRoom.data().name);
          }
        });
      } else {
        this.$toast.error("El Codi QR no es valid");
        this.isValid = false;
      }
      if (
        this.result.nomCognom &&
        this.result.direccio &&
        this.result.telefon &&
        this.result.segonTelefon
      ) {
        if (this.roomsDisponibles.length > 0) {
          this.isValid = true;
          this.waitValidation = true;
        } else {
          this.$toast.warning(
            "No tens campanyes actives. (+ info. consulta amb ACSA)"
          );
        }
      }

      // some more delay, so users have time to read the message
      await this.timeout(2000);

      this.turnCameraOn();
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    changeRoomState(theRoom) {
      if (this.roomsSelecionadas.includes(theRoom)) {
        var index = this.roomsSelecionadas.indexOf(theRoom);
        if (index !== -1) {
          this.roomsSelecionadas.splice(index, 1);
        }
      } else {
        this.roomsSelecionadas.push(theRoom);
      }
    },
    roomIsSelected(theRoom) {
      if (this.roomsSelecionadas.includes(theRoom)) {
        return true;
      } else {
        return false;
      }
    },
    resetQR() {
      this.waitValidation = false;
    },
    closeRetarded() {
      this.isLoading = false;
      this.waitValidation = false;
      this.enProceso = false;
      this.roomsSelecionadas = [];
      this.roomsDisponibles = [];
      this.rooms = "";
    },

    sendTickets() {
      //if(this.justOneTicket){
      this.isLoading = true;
      if (this.clientName){
        if (!this.enProceso) {
        this.enProceso = true;
        this.roomsSelecionadas.forEach((theRoom) => {
          this.rooms.forEach((theSecondRoom) => {
            if (theSecondRoom.data().name.includes(theRoom)) {
              const numberToDiscount =
                parseInt(theSecondRoom.data().ticketShops[this.myName]) -
                this.numberSelected;
              if (numberToDiscount < 0) {
                this.elTel = "";
                this.$toast.error(
                  "TICKETS " +
                    theSecondRoom.data().name +
                    " INSUFICIENTS (Demana una recarrega en ACSA)"
                );
                setTimeout(() => this.closeRetarded(), 2000);
              } else {
                theSecondRoom.data().ticketShops[this.myName] =
                  numberToDiscount;
                  this.$toast.success("Registre en campanya " + theSecondRoom.data().name);
                  //console.log(numberToDiscount);
                const roomData = {};
                roomData[`ticketShops.${this.myName}`] = numberToDiscount;
                this.updateTickets(
                  theSecondRoom.id,
                  roomData,
                  numberToDiscount
                );
              }
            }
          });
        });
      }
      } else {
        this.$toast.warning("Completa el nom del client");
        this.isLoading = false;
      }
      //this.justOneTicket = false;
    //}
      
    },

    

    async updateTickets(roomID, roomData, numberToDiscount) {
      try {
        await updateDoc(doc(db, "rooms", roomID), roomData);
      } catch (error) {
        console.error(error.message);
        this.$toast.error(error.message);
      } finally {
        
        let nuevoId = Math.random();
        nuevoId = parseFloat(numberToDiscount) + nuevoId;
        this.updateTable(roomID, nuevoId);
      }
    },

    async updateTable(roomID, nuevoId) {
      try {
        this.result.shop = this.myName;
        this.result.nuevoId = nuevoId;
        if (this.numberSelected === 10) {
          this.result.nomCognom = this.clientName;
          this.elTel = "";
          let result1 = { ...this.result }; 
          result1.nuevoId = result1.nuevoId + 3.14159;
          let timesArray = [result1];   

          //let timesArray = [result1];
          await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(this.result),
            ticketTable: arrayUnion(...timesArray),
          });
          //let result1 = { ...this.result };
          //result1.nuevoId = result1.nuevoId + 3.14159;
          //let result2 = { ...result1 };
          //result2.nuevoId = result2.nuevoId + 3.14159;
          //let result3 = { ...result2 };
          //result3.nuevoId = result3.nuevoId + 3.14159;
          //let result4 = { ...result3 };
          //result4.nuevoId = result4.nuevoId + 3.14159;
          //let result5 = { ...result4 };
          //result5.nuevoId = result5.nuevoId + 3.14159;
          //let result6 = { ...result5 };
          //result6.nuevoId = result6.nuevoId + 3.14159;
          //let result7 = { ...result6 };
          //result7.nuevoId = result7.nuevoId + 3.14159;
          //let result8 = { ...result7 };
          //result8.nuevoId = result8.nuevoId + 3.14159;
          //let result9 = { ...result8 };
          //result9.nuevoId = result9.nuevoId + 3.14159;
          //let result10 = { ...result9 };
          //result10.nuevoId = result10.nuevoId + 3.14159;

          //let timesArray = [
            //result1,
            //result2,
            //result3,
            //result4,
            //result5,
            //result6,
            //result7,
            //result8,
            //result9,
            //result10,
          //];
          //await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(...timesArray),
          //});
        } else if (this.numberSelected === 5) {
          this.result.nomCognom = this.clientName;
          this.elTel = "";
          let result1 = { ...this.result }; 
          result1.nuevoId = result1.nuevoId + 3.14159;
          let timesArray = [result1];   

          //let timesArray = [result1];
          await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(this.result),
            ticketTable: arrayUnion(...timesArray),
          });
          //let result1 = { ...this.result };
          //result1.nuevoId = result1.nuevoId + 3.14159;
          //let result2 = { ...result1 };
          //result2.nuevoId = result2.nuevoId + 3.14159;
          //let result3 = { ...result2 };
          //result3.nuevoId = result3.nuevoId + 3.14159;
          //let result4 = { ...result3 };
          //result4.nuevoId = result4.nuevoId + 3.14159;
          //let result5 = { ...result4 };
          //result5.nuevoId = result5.nuevoId + 3.14159;

          //let timesArray = [result1, result2, result3, result4, result5];

          //await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(...timesArray),
          //});
        } else if (this.numberSelected === 3) {

          this.result.nomCognom = this.clientName;
          this.elTel = "";
          let result1 = { ...this.result }; 
          result1.nuevoId = result1.nuevoId + 3.14159;
          let timesArray = [result1];   

          //let timesArray = [result1];
          await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(this.result),
            ticketTable: arrayUnion(...timesArray),
          });
          //let result1 = { ...this.result };
          //result1.nuevoId = result1.nuevoId + 3.14159;
          //let result2 = { ...result1 };
          //result2.nuevoId = result2.nuevoId + 3.14159;
          //let result3 = { ...result2 };
          //result3.nuevoId = result3.nuevoId + 3.14159;

          //let timesArray = [result1, result2, result3];

          //await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(...timesArray),
          //});
        } else if (this.numberSelected === 7) {
          this.result.nomCognom = this.clientName;
          this.elTel = "";
          let result1 = { ...this.result }; 
          result1.nuevoId = result1.nuevoId + 3.14159;
          
          let timesArray = [result1];
          

          //let timesArray = [result1];
          await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(this.result),
            ticketTable: arrayUnion(...timesArray),
          });
          //let result1 = { ...this.result };
          //result1.nuevoId = result1.nuevoId + 3.14159;
          //let result2 = { ...result1 };
          //result2.nuevoId = result2.nuevoId + 3.14159;
          //let result3 = { ...result2 };
          //result3.nuevoId = result3.nuevoId + 3.14159;
          //let result4 = { ...result3 };
          //result4.nuevoId = result4.nuevoId + 3.14159;
          /*let result5 = { ...result4 };
          result5.nuevoId = result5.nuevoId + 3.14159;
          let result6 = { ...result5 };
          result6.nuevoId = result6.nuevoId + 3.14159;
          let result7 = { ...result6 };
          result7.nuevoId = result7.nuevoId + 3.14159;

          let timesArray = [result1, result2, result3, result4, result5, result6, result7];

          await updateDoc(doc(db, "rooms", roomID), {
            ticketTable: arrayUnion(...timesArray),
          });*/
        } else {
          
            this.result.nomCognom = this.clientName;
          this.elTel = "";
          let result1 = { ...this.result }; 
          result1.nuevoId = result1.nuevoId + 3.14159;
          
          let timesArray = [result1];   
          //console.log(timesArray);   

          //let timesArray = [result1];
          await updateDoc(doc(db, "rooms", roomID), {
            //ticketTable: arrayUnion(this.result),
            ticketTable: arrayUnion(...timesArray),
          });
         
          

        }
        //this.$toast.success("Tickets afegits");
      } catch (error) {
        console.error(error.message);
        this.$toast.error(error.message);
      } finally {
        if(this.addClient){
          this.createClient(this.result);
        }else{
          this.updateClient(this.result);
        }
        
      }
    },

    async updateClient() {
        const q = query(collection(db, "clients"));
        const querySnapshot = await getDocs(q);
        
        this.clientResult = querySnapshot;
        querySnapshot.forEach((theClient) => {
          if (
            theClient.data().tlf.toString() == this.result.telefon
          ) {
            this.clientRef = theClient.id;
            
            this.writeNewClient();
            }
        }); 
    },

    async writeNewClient(){
      try {
            await updateDoc(doc(db, "clients", this.clientRef), {
              name : this.result.nomCognom
            });
            } catch (error) {
              console.error(error.message);
              this.$toast.error(error.message);
            } finally {
              //this.$toast.success("Registre correcte");
              this.clientResult = "";
              this.clientRef = "";
              this.isLoading = false;
              this.waitValidation = false;
              this.enProceso = false;
              this.roomsSelecionadas = [];
              this.roomsDisponibles = [];
              this.rooms = "";
              this.clients= "";
              this.clientName= "";
              this.addClient= false;
              this.existClient= false;
              this.noReady= true;
              this.readyName= false;
              this.readyNewName= false;
              this.numberSelected = 1;
            }
    },

    async createClient(theResult) {
      
        
      try {
        // eslint-disable-next-line no-undef
        if (this.justOneCreate){
          this.justOneCreate = false;
        await addDoc(collection(db, "clients"), {
        name: theResult.nomCognom,
        tlf: theResult.telefon
      })
      }
      } catch (error) {
        console.error(error.message);
        this.$toast.error(error.message);
      } finally {   
        //this.$toast.success("Registre correcte");
      
        this.clientResult = "";
              this.clientRef = "";
              this.isLoading = false;
              this.waitValidation = false;
              this.enProceso = false;
              this.roomsSelecionadas = [];
              this.roomsDisponibles = [];
              this.rooms = "";
              this.clients= "";
              this.clientName= "";
              this.addClient= false;
              this.existClient= false;
              this.noReady= true;
              this.readyName= false;
              this.readyNewName= false;
              this.numberSelected = 1;
        /*this.waitValidation = false;
        this.enProceso = false;
        this.roomsSelecionadas = [];
        this.roomsDisponibles = [];
        this.rooms = "";
        this.isLoading = false;
        this.clients= "";
       this.clientName= "";
       this.addClient= false;
       this.existClient= false;
       this.noReady= true;
       this.readyName= false;
       this.readyNewName= false;
       this.numberSelected = 1;*/
      }
    
    
    },

   async checkNumber(phone) {
      if(phone.toString().length == 9){
        this.bloqNumber = true;
        this.clients = "";
        this.clientName = "";
       // this.justOneTicket = true;
        
        const q = query(collection(db, "clients"));
        const querySnapshot = await getDocs(q);
        this.clients = querySnapshot;
        querySnapshot.forEach((theClient) => {
          if (
            theClient.data().tlf == phone.toString() &&
            !theClient.data().finished
          ) {
            this.clientName = theClient.data().name;
          }else {
            //console.log("VAMOS A AÑADIRLO")
          }
        });
        if(!this.clientName){
          this.addClient = true;
          this.noReady = false;
          this.bloqNumber = false;
          //console.log("no encuentro este cleinte, se habilita boton addclient")
          this.elPhone = phone;
          //this.onValidNumber(phone);
         
        }else{
          
          this.existClient = true;
          this.noReady = false;
          this.bloqNumber = false;
          this.elPhone = phone;
          //this.onValidNumber(phone);
          
        }
      }else{
        this.addClient = false;
        this.existClient = false;
        this.noReady = true;
        this.bloqNumber = false;
        this.clients = "";
        this.clientName = "";
        
        
      }
     
    },

    async onValidNumber(thePhone) {
      this.justOneCreate = true;
      this.isLoading = true;
      // variables reseteadas
      (this.roomsDisponibles = []), (this.result = [{}]);
      this.numberSelected = 1;
      //console.log(this.numberSelected);
      this.rooms = "";
      this.result.telefon = thePhone.toString();

      // consulta base de datos para las campañas que participa mi tienda
      const q = query(collection(db, "rooms"));
      const querySnapshot = await getDocs(q);
      this.rooms = querySnapshot;
      querySnapshot.forEach((theRoom) => {
        if (
          theRoom.data().checkedShops.includes(this.myName) &&
          !theRoom.data().finished
        ) {
          this.roomsDisponibles.push(theRoom.data().name);
          this.roomsSelecionadas.push(theRoom.data().name);
        }
      });
      
      if (
        this.result.telefon
      ) {
        if (this.roomsDisponibles.length > 0) {
          
          this.noReady = false;
          this.bloqNumber = false;
          console.log("uno");
          this.sendTickets();

        } else { 
          this.noReady = true;
          this.isLoading = false;
          this.$toast.warning(
            "No tens campanyes actives. (+ info. consulta amb ACSA)"
          );
            this.clientResult = "";
              this.clientRef = "";
              this.isLoading = false;
              this.waitValidation = false;
              this.enProceso = false;
              this.roomsSelecionadas = [];
              this.roomsDisponibles = [];
              this.rooms = "";
              this.clients= "";
              this.clientName= "";
              this.addClient= false;
              this.existClient= false;
              this.noReady= true;
              this.readyName= false;
              this.readyNewName= false;
              this.numberSelected = 1;
        }
      }

      // some more delay, so users have time to read the message
      await this.timeout(2000);

      

    },

  },
};
</script>

