import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCyeOrLtQ1JFZtPun0DAbXN8Q9Z9vOvmas",
  authDomain: "campanyesacsa.firebaseapp.com",
  projectId: "campanyesacsa",
  storageBucket: "campanyesacsa.appspot.com",
  messagingSenderId: "53261112259",
  appId: "1:53261112259:web:0d65f576f866de4f91a376",
  measurementId: "G-BLQ7D43DNJ"
};

// Initialize Firebase
export const fb = initializeApp(firebaseConfig);
export const db = getFirestore(fb);
